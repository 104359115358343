import React from 'react';
import { Box } from '@mui/material';

import { Logo } from 'src/components/Logo/Logo';

interface Props {
  className?: string;
}

export const TopNav = (props: Props) => {
  return (
    <Box>
      <Logo />
    </Box>
  );
};
