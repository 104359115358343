import { useRouter } from 'next/router';

import { useSignupGuest } from 'src/api/auth/hooks';
import { gqlClient } from 'src/api/gqlClient';
import { RoutePath } from 'src/constants/RoutePath';
import { ShowCardsSetting, useCreateGameMutation } from 'src/gql-generated';
import { useAuth } from 'src/utils/auth/useAuth';
import { VotingSystem } from 'src/utils/constants';

import { useGuestUser } from './useGuestUser';
import { useUserSetupDialog } from './useUserSetupDialog';

export const useCreateInstantGame = () => {
  const createGameMutation = useCreateGameMutation(gqlClient);
  const { user } = useAuth();
  const router = useRouter();
  const { openUserSetupDialog } = useUserSetupDialog();

  const processCreateInstantGame = async ({ userId }: { userId: string }) => {
    try {
      const { createGame } = await createGameMutation.mutateAsync({
        input: {
          isPrivate: false,
          showCardsSetting: ShowCardsSetting.Anyone,
          name: 'Planning Session',
          userId,
          votingSystem: VotingSystem.fibonacci,
        },
      });

      router.push(`${RoutePath.Game}/${createGame.id}`);
    } catch (error) {}
  };

  const createInstantGame = async () => {
    const userId = user?.id;

    if (!userId) {
      openUserSetupDialog({
        onDone: (user) => {
          processCreateInstantGame({ userId: user.id });
        },
      });

      return;
    }

    processCreateInstantGame({ userId });
  };

  return { createInstantGame };
};
