import React from 'react';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { MoreHorizontalIcon } from 'lucide-react';
import { useRouter } from 'next/router';

import { gqlClient } from 'src/api/gqlClient';
import { Txt } from 'src/components/Txt/Txt';
import { RoutePath } from 'src/constants/RoutePath';
import { colors } from 'src/design-system/design-system.colors';
import {
  useDeleteGameMemberMutation,
  useGetGameMemberQuery,
} from 'src/gql-generated';
import { useBreakpoints } from 'src/hooks/useBreakpoints';
import { useCreateInstantGame } from 'src/hooks/useCreateInstantGame';
import { useJoinGameDialog } from 'src/hooks/useJoinGameDialog';
import { useLoginDialog } from 'src/hooks/useLoginDialog';
import { useQueryParam } from 'src/hooks/useQueryParam';
import { useUserSetupDialog } from 'src/hooks/useUserSetupDialog';
import { useAuth } from 'src/utils/auth/useAuth';
import { CookieKey } from 'src/utils/CookieKey';
import { cookiesService } from 'src/utils/cookiesService';

import { TopNav } from './TopNav';

interface Props {
  children: React.ReactNode;
}

export const MainLayout = (props: Props) => {
  const { children } = props;
  const gameId = useQueryParam('gameId');
  const { isSmUp } = useBreakpoints();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const auth = useAuth();
  const { user } = auth;
  const userId = cookiesService.get(CookieKey.UserId);
  const router = useRouter();
  const { openLoginDialog } = useLoginDialog();
  const { openUserSetupDialog } = useUserSetupDialog();
  const deleteGameMemberMutation = useDeleteGameMemberMutation(gqlClient);
  const { data } = useGetGameMemberQuery(
    gqlClient,
    { input: { gameId, userId } },
    { enabled: !!gameId && !!userId }
  );
  const gameMember = data?.getGameMember;
  const { createInstantGame } = useCreateInstantGame();
  const { openJoinGameDialog } = useJoinGameDialog();

  const handleMenuClick: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleCreateInstantGameClick = async () => {
    closeMenu();

    createInstantGame();
  };

  const handleJoinGameClick = () => {
    closeMenu();

    if (user) {
      openJoinGameDialog();
    } else {
      openUserSetupDialog({
        onDone: () => openJoinGameDialog(),
      });
    }
  };

  const handleLeaveClick = async () => {
    closeMenu();

    if (!gameMember?.id) return;

    await router.push(RoutePath.Home);
    deleteGameMemberMutation.mutateAsync({
      input: { gameMemberId: gameMember.id },
    });
  };

  return (
    <Box
      minHeight="100vh"
      bgcolor={colors.dark[800]}
      position="relative"
      p={1.5}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box
        component="header"
        maxWidth="1400px"
        width="100%"
        mb={{ xs: 2, sm: 4 }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <TopNav />

        <Box display="flex" alignItems="center">
          {(!!user?.displayName || !!user?.firstName) && isSmUp && (
            <Txt
              fontWeight={600}
              mr={1}
              onClick={() => {
                router.push(RoutePath.Profile);
                closeMenu();
              }}
            >
              {user?.displayName || user?.firstName}
            </Txt>
          )}

          <span>
            <Box component={IconButton} onClick={handleMenuClick}>
              <MoreHorizontalIcon color={colors.light} />
            </Box>

            <Box
              component={Menu}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={closeMenu}
              mt={1}
            >
              <MenuItem onClick={handleCreateInstantGameClick}>
                <Txt fontWeight={600} py={0.5} color="primary" noWrap>
                  Create instant game
                </Txt>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  router.push(RoutePath.CreateGame);
                  closeMenu();
                }}
              >
                <Txt fontWeight={600} py={0.5} noWrap>
                  Create custom game
                </Txt>
              </MenuItem>

              <MenuItem onClick={handleJoinGameClick}>
                <Txt fontWeight={600} py={0.5} noWrap>
                  Join game
                </Txt>
              </MenuItem>

              {!!gameId && !!gameMember && (
                <MenuItem onClick={handleLeaveClick}>
                  <Txt fontWeight={600} py={0.5} noWrap>
                    Leave game
                  </Txt>
                </MenuItem>
              )}

              {/* {auth.isLoggedIn && (
                <MenuItem
                  onClick={() => {
                    router.push(RoutePath.Profile);
                    closeMenu();
                  }}
                >
                  <Txt fontWeight={600} py={0.5} noWrap>
                    Profile
                  </Txt>
                </MenuItem>
              )} */}

              {auth.isLoggedIn && (
                <MenuItem
                  onClick={async () => {
                    closeMenu();
                    await router.push(RoutePath.GameOptions);
                    auth.logout();
                  }}
                >
                  <Txt fontWeight={600} py={0.5} noWrap>
                    Log out
                  </Txt>
                </MenuItem>
              )}

              {!auth.isLoggedIn && (
                <MenuItem
                  onClick={() => {
                    closeMenu();
                    setTimeout(() => {
                      openLoginDialog();
                    }, 200);
                  }}
                >
                  <Txt fontWeight={600} py={0.5} noWrap>
                    Log In
                  </Txt>
                </MenuItem>
              )}

              {!auth.isLoggedIn && (
                <MenuItem
                  onClick={() => {
                    router.push(RoutePath.Register);
                    closeMenu();
                  }}
                >
                  <Txt fontWeight={600} py={0.5} noWrap>
                    Sign Up
                  </Txt>
                </MenuItem>
              )}
            </Box>
          </span>
        </Box>
      </Box>

      <Box height="100%" maxWidth="1400px" width="100%">
        {children}
      </Box>
    </Box>
  );
};

export const getMainLayout = (page: JSX.Element) => (
  <MainLayout>{page}</MainLayout>
);
