import React from 'react';
import { Box, Stack } from '@mui/material';
import Link from 'next/link';

import { RoutePath } from 'src/constants/RoutePath';
import { colors } from 'src/design-system/design-system.colors';

import { Txt } from '../Txt/Txt';

export const Logo = () => {
  return (
    <Link href={RoutePath.Home}>
      <Box
        bgcolor={colors.dark[700]}
        pl={1.5}
        pr={2.5}
        py={1}
        borderRadius="1px"
      >
        <Stack direction="row" spacing={0.5} alignItems="center">
          {/* <Bolt color="primary" fontSize="small" /> */}
          <Box
            component="img"
            height="24px"
            width="24px"
            src="/images/lightning-bolt-logo.svg"
          />
          <Txt
            color={colors.primary[500]}
            fontWeight="bold"
            fontSize={{ xs: 12, md: 14 }}
          >
            Instant Planning Poker
          </Txt>
        </Stack>
      </Box>
    </Link>
  );
};
