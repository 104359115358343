import React from 'react';
import { Box, Button, Grid } from '@mui/material';
import { useRouter } from 'next/router';

import { Txt } from 'src/components/Txt/Txt';
import { RoutePath } from 'src/constants/RoutePath';
import { useCreateInstantGame } from 'src/hooks/useCreateInstantGame';
import { useJoinGameDialog } from 'src/hooks/useJoinGameDialog';
import { useUserSetupDialog } from 'src/hooks/useUserSetupDialog';
import { PageComponent } from 'src/types/types';
import { useAuth } from 'src/utils/auth/useAuth';

export const GameOptionsPage: PageComponent = () => {
  const { createInstantGame } = useCreateInstantGame();
  const { openUserSetupDialog } = useUserSetupDialog();
  const { user } = useAuth();
  const router = useRouter();
  const { openJoinGameDialog } = useJoinGameDialog();

  const handleCreateInstantGameClick = async () => {
    try {
      await createInstantGame();
    } catch {}
  };

  const handleCreateCustomGameClick = () => {
    if (user) {
      router.push(RoutePath.CreateGame);
    } else {
      openUserSetupDialog({ onDone: () => router.push(RoutePath.CreateGame) });
    }
  };

  const handleJoinGameClick = () => {
    if (user) {
      openJoinGameDialog();
    } else {
      openUserSetupDialog({ onDone: () => openJoinGameDialog() });
    }
  };

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        p={{ sm: 4 }}
        pt={3}
      >
        <Box width="100%" maxWidth="500px">
          <Grid container spacing={4} justifyContent="center" mb={4}>
            <Grid item xs={12} sm={12}>
              <Button
                onClick={handleCreateInstantGameClick}
                fullWidth
                variant="contained"
                color="primary"
                startIcon={
                  <Box
                    component="img"
                    height="30px"
                    width="30px"
                    src="/images/lightning-bolt-logo.svg"
                    mr={-1}
                  />
                }
                size="large"
              >
                Create an instant game
              </Button>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button
                onClick={handleCreateCustomGameClick}
                fullWidth
                variant="outlined"
                color="primary"
                size="large"
              >
                Create a custom game
              </Button>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                onClick={handleJoinGameClick}
                size="large"
              >
                Join an existing game
              </Button>
            </Grid>
          </Grid>

          <Box mb={4}>
            <a
              href="https://en.wikipedia.org/wiki/Planning_poker"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Txt
                color="primary"
                fontSize={14}
                fontWeight={700}
                textAlign="center"
              >
                What is planning poker?
              </Txt>
            </a>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
